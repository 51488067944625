import React, {useState} from 'react'
import {
  Container,
  Row
} from 'reactstrap'
import {CodeInput} from './CodeInput/index'
import './login.css'
import {motion} from 'framer-motion'
import {useSelector, useDispatch} from 'react-redux'
import {onCodeLogin, authFailed} from '../../modules/actions/Login/ActionCreator'
import {executeRequest} from '../../modules/dataActions/dataFetcher'
import {NotificationManager} from 'react-notifications'
const transition = { duration: 0.55, ease: [0.43, 0.13, 0.23, 0.96] };

const listVariants = {
    initial: { scale: 0.9, opacity: 0 },
    enter: { scale: 1, opacity: 1, transition },
    exit: {
        scale: 0.5,
        opacity: 0,
        transition: { duration: 0.55, ...transition }
    }
};

const MAX_CODE_ATTEMPTS = 5;

const LoginCode = ({history}) => {
  const [codeAttempts, setCodeAttempts] = useState(0);
  const [codeLoginAttempt, setCodeLoginAttempt] = useState(false);
  const dispatch = useDispatch();

  const loggedInEmail = useSelector(state => state.authentication.loggedInEmail);

  const handleSubmit = async (code) => {
   
    let data = {
      code: code,
      email: loggedInEmail
    }

    setCodeLoginAttempt(true);

    const response = await executeRequest('login/codeLogin', data, dispatch)

    setCodeLoginAttempt(false);

    if(response.jwtToken) {
        window.sessionStorage.setItem("token", response.jwtToken);
        dispatch(onCodeLogin({isAuthenticated: true}));
        history.push('/dashboard')
    } else {
        if(codeAttempts + 1 === MAX_CODE_ATTEMPTS) {
          NotificationManager.error("Liian paljon virheellisiä yrityksiä", "", 2000);
          dispatch(authFailed());
        } else {
          NotificationManager.error("Koodi oli väärin", "Virhe", 3000);
          setCodeAttempts(codeAttempts+1);
        }
        
    }

  }

  return (
    <motion.div variants={listVariants} exit="exit" className="h-100" key="loginCode">
        <Container className="h-100">
            <motion.div
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
                    className="h-100"
                >
                <Row className="justify-content-center align-items-center h-100">
                    <div className="col-md-8 sm-12 greybox loginbox">
                        <div>
                            <h2 className="text-center" style={{color: '#fff'}}>Syötä koodi</h2>
                            <CodeInput onSubmit={handleSubmit} codeLoginAttempt={codeLoginAttempt}/>
                        </div>
                    </div>
                </Row>
            </motion.div>
        </Container>
    </motion.div>
  )
}

export default LoginCode;
