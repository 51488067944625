import React from 'react'

const BoxSearchBar = props => {
  const { placeholder, value, onChange } = props

  return (
    <div style={{ marginLeft: '0.5em' }}>
        <form id="search-bar">
            <input type="search" className="search-bar-input" placeholder={ placeholder } value={ value } onChange={ (e) => onChange( e.target.value ) } />
        </form>
    </div>
  )
}

export default BoxSearchBar