import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import PermissionItem from './PermissionItem';
import {Row, Col} from 'reactstrap';
import {motion} from 'framer-motion';
const transition = { duration: 0.35, ease: [0.43, 0.13, 0.23, 0.96] };

const listVariants = {
    initial: { scale: 0.9, opacity: 0 },
    enter: { scale: 1, opacity: 1, transition },
    exit: {
        scale: 0.5,
        opacity: 0,
        transition: { duration: 0.35, ...transition }
    }
};

const PermissionList = ({modulesInUse, enterpriseId, changePermission, changeTrialActive, trial = false, trialEnd = false}) => {

    const handlePermission = (moduleInUse) => {
        let enterpriseStack = {
            enterpriseId: enterpriseId,
            modulesInUse: {
                ...modulesInUse,
                [moduleInUse]: !modulesInUse[moduleInUse]
            }
        }

        changePermission(enterpriseStack);
    }

    const handleTrial = () => {    
        let payload = {
            enterpriseId: enterpriseId,
            type: "trial",
            trial: !trial
        }
        changeTrialActive(payload)
    }

    const handleCountChange = (params) => {
        const {id,value} = params
        if(value !== "") {
            if(!isNaN(value)) {
                let newCount = parseInt(value);
                let enterpriseStack = {
                    enterpriseId: enterpriseId,
                    modulesInUse: {
                        ...modulesInUse,
                        [id]: newCount
                    }
                }
                changePermission(enterpriseStack);
            }
        }
    }
    
    const handleTrialEndSave = (params) => {
        let payload = {
            enterpriseId: enterpriseId,
            type: "trial",
            trialEnd: params
        }
        changePermission(payload);
    }

    const {surveyManagement, limitedSurveyManagement, limitedSurveyCount, clientManagement, taskManagement, embedManagement, riskManagement, planManagement, limitedPlanManagement, limitedPlanCount, projectManagement, limitedProjectManagement, limitedProjectCount, ganttEnabled = false, privateDocuments=false, annotationManagement = false, signatureManagement = false, wordConversion = false, customPlanTarget = false, contactManagement = false, trelloEnabled = false} = modulesInUse;
    return (
        <motion.div variants={listVariants} exit="exit">
            <motion.div
            positionTransition
            exit={{opacity: 0, x: -100}}>
                <Row>
                    <Col xl="12">
                        <div className="dashboard-medium-box">
                            <div className="box-header bigger-text" style={{ display: 'flex' }}>
                                <p>Oikeudet</p>
                            </div>
                        
                        <Scrollbars
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                            style={{ width: "100%", height: '500px' }}
                            className="custom-scrollbars"
                        >
                            <PermissionItem
                                label={"Kokeilujakso"}
                                active={trial}
                                trialEndDate={trialEnd}
                                handlePermission={handleTrial}
                                handleTrialEndSave={handleTrialEndSave}
                            />
                            <PermissionItem
                                label={"Gantt"}
                                active={ganttEnabled || false}
                                moduleInUse={"ganttEnabled"}
                                handlePermission={handlePermission}
                            />
                            <PermissionItem
                                label={"TrelloTimeline (Lomakkeet)"}
                                active={trelloEnabled || false}
                                moduleInUse={"trelloEnabled"}
                                handlePermission={handlePermission}
                            />
                            <PermissionItem 
                                label={"Lomakkeet"} 
                                moduleInUse={"planManagement"}
                                active={planManagement || false}
                                limitedPlans={limitedPlanManagement}
                                limitedPlanCount={limitedPlanCount}  
                                handlePermission={handlePermission}
                                handleCountChange={handleCountChange}
                            />
                            {planManagement &&
                                <PermissionItem
                                    label={"Lomakkeen Asiakas => Vastaanottaja"}
                                    active={customPlanTarget || false}
                                    moduleInUse={"customPlanTarget"}
                                    handlePermission={handlePermission}
                                />
                            }
                            {planManagement &&
                                <PermissionItem
                                    label={"Sähköinen allekirjoitus"}
                                    active={signatureManagement || false}
                                    moduleInUse={"signatureManagement"}
                                    handlePermission={handlePermission}
                                />
                            }
                            {planManagement &&
                                <PermissionItem
                                    label={"Yksityinen lomake"}
                                    active={privateDocuments || false}
                                    moduleInUse={"privateDocuments"}
                                    handlePermission={handlePermission}
                                />
                            }
                            {planManagement &&
                                <PermissionItem
                                    label={"Lomakkeen kommentointi"}
                                    active={annotationManagement || false}
                                    moduleInUse={"annotationManagement"}
                                    handlePermission={handlePermission}
                                />
                            }
                            {planManagement &&
                                <PermissionItem
                                    label={"Word doc -lataus"}
                                    active={wordConversion || false}
                                    moduleInUse={"wordConversion"}
                                    handlePermission={handlePermission}
                                />
                            }
                            <PermissionItem 
                                label={"Projektit"} 
                                moduleInUse={"projectManagement"}
                                active={projectManagement || false}
                                limitedProjects={limitedProjectManagement}
                                limitedProjectCount={limitedProjectCount}  
                                handlePermission={handlePermission}
                                handleCountChange={handleCountChange}
                            />
                            <PermissionItem 
                                label={"Kartoitukset"} 
                                moduleInUse={"surveyManagement"}
                                active={surveyManagement || false}
                                limitedSurveys={limitedSurveyManagement}
                                limitedSurveyCount={limitedSurveyCount} 
                                handlePermission={handlePermission}
                                handleCountChange={handleCountChange}
                            />
                            <PermissionItem 
                                label={"Yritykset"} 
                                moduleInUse={"clientManagement"}
                                active={clientManagement || false} 
                                handlePermission={handlePermission}
                            />
                            <PermissionItem 
                                label={"Kontaktit"} 
                                moduleInUse={"contactManagement"}
                                active={contactManagement || false} 
                                handlePermission={handlePermission}
                            />
                            <PermissionItem 
                                label={"Tehtävät"} 
                                moduleInUse={"taskManagement"}
                                active={taskManagement || false} 
                                handlePermission={handlePermission}
                            />
                            <PermissionItem 
                                label={"Riskit"} 
                                moduleInUse={"riskManagement"}
                                active={riskManagement || false} 
                                handlePermission={handlePermission}
                            />
                            <PermissionItem 
                                label={"Embed"} 
                                moduleInUse={"embedManagement"}
                                active={embedManagement || false} 
                                handlePermission={handlePermission}
                            />
                       
                        </Scrollbars>
                        </div>
                    </Col>
                </Row>
            </motion.div>
        </motion.div>
    )
}

export default PermissionList;