import * as ActionTypes from '../modules/actions/Enterprise/ActionTypes';
export const initialState = {
    enterprises: [],
    filter: '',
    enterprise: {},
    fetchStatus: false
  }

const handleUsers = (editedUser, users) => {
    let userStack = users.map(client => {
        if(client.id === editedUser.id) {
            return editedUser;
        } else {
            return client;
        }
    })

    return userStack;
}

function reducer(state = initialState, action) {
    switch(action.type) {
        case ActionTypes.SET_ENTERPRISES:
            return {
                ...state,
                enterprises: action.payload
            }
        case ActionTypes.SET_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case ActionTypes.SET_ENTERPRISE:
            return {
              ...state,
              enterprise: action.payload
            }
        case ActionTypes.SET_USER_DATA:
            return {
                ...state,
                enterprise: {
                    ...state.enterprise,
                    users: [
                        ...handleUsers(action.payload.user, state.enterprise.users)
                    ]
                }
            }
        case ActionTypes.ADD_NEW_USER:
            return {
                ...state,
                enterprise: {
                    ...state.enterprise,
                    users: [
                        ...state.enterprise.users,
                        action.payload.user
                    ]
                }
            }
        case ActionTypes.SET_FETCH_STATUS:
            return {
                ...state,
                fetchStatus: action.payload

            }
        case ActionTypes.CHANGE_PERMISSION:
          return {
            ...state,
            enterprise: {
                ...action.payload
            }
          }
        case ActionTypes.SAVE_LOGO:
            return {
                ...state,
                enterprise: {
                    ...state.enterprise,
                    logo: action.payload
                }
            }
        case ActionTypes.ADD_NEW_ENTERPRISE:
            return {
                ...state,
                enterprises: [
                    ...state.enterprises,
                    action.payload
                ]
            }
        default:
            return state
    }
  }

  export default reducer;