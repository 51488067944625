import React, {useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {useInView} from 'react-intersection-observer'
import {motion} from 'framer-motion'
import { SingleDatePicker } from 'react-dates';
import moment from 'moment'
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const PermissionItem = ({label, moduleInUse, active, handlePermission, handleTrialEndSave, limitedSurveys = null, limitedSurveyCount = 5, handleCountChange, limitedPlanCount = 15, limitedPlans = false, limitedProjectCount = 2, limitedProjects = false, trialEndDate = false}) => {
    const [surveyCount, setSurveyCount] = useState(limitedSurveyCount);
    const [planCount, setPlanCount] = useState(limitedPlanCount)
    const [projectCount, setProjectCount] = useState(limitedProjectCount)
    const [focusedDateInput, setFocusedDateInput] = useState(false)
    const [trialEnd, setTrialEnd] = useState(trialEndDate)
    const [ref, inView] = useInView({
        threshold: 0,
    })

    useEffect(()=>{
        if(trialEndDate){
            let date = moment(trialEndDate, 'MMM D, YYYY h:m:s A', 'en' ).format( 'YYYY-MM-DD' ).toString()
            setTrialEnd(new Date(date))
        }
    },[trialEndDate])
    useEffect(() => {
        setSurveyCount(limitedSurveyCount)
    }, [limitedSurveyCount])

    const variants = {
        visible: i => ({
            opacity: 1,
            x: 0,
            transition: {delay: i * 0.3, ease: 'easeIn', duration: 0.2}
        }),
        hidden: {
            opacity: 0
        },
        buttonActive: {
            backgroundColor: '#b5e413',
            color: '#FFF',
        },
        buttonNotActive: {
            backgroundColor: '#ED5652',
            color: '#FFF',
        }
    }

    const setCount = value => {
        if(value !== "") {
            if(!isNaN(value)) {
                setSurveyCount(value);
            }
        } else if(value === "") {
            setSurveyCount(value);
        }
    }

    const handleTimeChange = (date) => {
        // let time = moment.utc(date, 'MMM D, YYYY h:m:s A', 'en' ).local().format( 'DD.MM.YYYY HH:mm' )
       
        setTrialEnd(date)
    }

    const handleTrialEnd = () => {
        if(trialEnd){
            let time = moment.utc(trialEnd, 'MMM D, YYYY h:m:s A', 'en' ).local().format( 'DD.MM.YYYY' )
            handleTrialEndSave(time)
        }
    }

    return (
        <motion.div
            initial={{opacity: 0, x: 150}}
            animate={inView ? "visible": "hidden"}
            variants={variants}
            exit={{opacity: 0, x: -100}}
            ref={ref}
            style={{}}
            positionTransition
        >
            <Row className="dashboard-survey-row sent-survey-row" onClick={() => handlePermission(moduleInUse)}>
                <Col xs="8">
                    <p>{label}</p>
                </Col>
                <Col xs="4">
                    <motion.div
                        animate={(inView && active ? 'buttonActive' : 'buttonNotActive')}
                        variants={variants}
                        className="small-white-button"
                        style={{ fontWeight:'bold', textAlign: active? 'left':'right'}}
                    > 
                        {active ? 'Aktiivinen' : 'Pois päältä'}
                    </motion.div>
                </Col>
            </Row>
            {(label === "Kokeilujakso" && active) &&
                <motion.div
                    variants={variants}
                    animate={inView ? "visible": "hidden"}
                    style={{ backgroundColor:'#ebebeb'}}
                >
                    <Row className="dashboard-survey-row sent-survey-row">
                        <Col xs="8">
                            <div style={{display:'flex', marginLeft:'2em'}}>
                                <p style={{ marginRight: '1em'}}>Viimeinen voimassaolopäivä</p>
                                <ReactDatePicker 
                                    selected={trialEnd}
                                    onChange={(date) => handleTimeChange(date)}
                                    dateFormat="dd.MM.yyyy"
                                />
                                {/* <SingleDatePicker 
                                    date={trialEnd ? moment(trialEnd) : null}
                                    id="start"
                                    onDateChange={(date) => setTrialEnd(date)}
                                    placeholder="Viim. pvm"
                                    appendToBody={false}
                                    focused={focusedDateInput}
                                    onFocusChange={({focused})  =>setFocusedDateInput(focused)}
                                    hideKeyboardShortcutsPanel={true}
                                    showClearDate 
                                    isOutsideRange={()=>false}
                                    isDayHighlighted={checkToday}
                                    block
                                    readOnly
                                /> */}
                            </div>
                        </Col>
                        <Col xs="4">
                            <div>
                                <button className="small-white-button" style={{fontSize: '0.8em', marginTop: '0em'}} onClick={() => handleTrialEnd()}>Tallenna</button>
                            </div>
                        </Col>
                    </Row>
                </motion.div>
            }
            {(label === "Kartoitukset" && active) &&
                <motion.div
                    variants={variants}
                    animate={inView ? "visible": "hidden"}
                    style={{ backgroundColor:'#ebebeb'}}
                >
                    <Row className="dashboard-survey-row sent-survey-row" onClick={() => handlePermission("limitedSurveyManagement")}>
                        <Col xs="8">
                            <p>Rajoitetut kartoitukset</p>
                        </Col>
                        <Col xs="4">
                            <motion.div
                                animate={(inView && limitedSurveys ? 'buttonActive' : 'buttonNotActive')}
                                variants={variants}
                                className="small-white-button"
                                style={{ fontWeight:'bold', textAlign:limitedSurveys ? 'left':'right' }}
                            >
                                {limitedSurveys ? 'Aktiivinen' : 'Pois päältä'}
                            </motion.div>
                        </Col>
                    </Row>
                    {limitedSurveys && 
                        <Row className="dashboard-survey-row sent-survey-row">
                            <Col xs="8">
                                <div style={{display:'flex', marginLeft:'2em'}}>
                                    <label>Kartoitusten määrä</label> 
                                    <input className="limited-input-bar" name="limited-survey-count" type="text" style={{width: '4em', height:'2em'}} value={surveyCount} onChange={(e) => setCount(e.target.value)}></input>
                                </div>
                            </Col>
                            <Col xs="4">
                                <div>
                                    <button className="small-white-button" style={{fontSize: '0.8em', marginTop: '0em'}} onClick={() => handleCountChange({id:"limitedSurveyCount", value:surveyCount})}>Tallenna</button>
                                </div>
                            </Col>
                            
                        </Row>
                    }
                </motion.div>
            }
            {(label === "Lomakkeet" && active) &&
                <motion.div
                    variants={variants}
                    animate={inView ? "visible": "hidden"}
                    style={{ backgroundColor:'#ebebeb'}}
                >
                    <Row className="dashboard-survey-row sent-survey-row" onClick={() => handlePermission("limitedPlanManagement")}>
                        <Col xs="8">
                            <p>Rajoitetut Lomakkeet</p>
                        </Col>
                        <Col xs="4">
                            <motion.div
                                animate={(inView && limitedPlans ? 'buttonActive' : 'buttonNotActive')}
                                variants={variants}
                                className="small-white-button"
                                style={{ fontWeight:'bold', textAlign:limitedPlans ? 'left':'right'}}
                            > 
                                {limitedPlans ? 'Aktiivinen' : 'Pois päältä'}
                            </motion.div>
                        </Col>
                    </Row>
                    {limitedPlans && 
                        <Row className="dashboard-survey-row sent-survey-row">
                            <Col xs="8">
                                <div style={{display:'flex', marginLeft:'2em'}}>
                                    <label>Lomakkeiden määrä</label> 
                                    <input className="limited-input-bar" name="limited-plan-count" type="text" style={{width: '4em', height:'2em'}} value={planCount} onChange={(e) => setPlanCount(e.target.value)}></input>
                                </div>
                            </Col>
                            <Col xs="4">
                                <div>
                                    <button className="small-white-button" style={{fontSize: '0.8em', marginTop: '0em'}} onClick={() => handleCountChange({id:"limitedPlanCount", value:planCount})}>Tallenna</button>
                                </div>
                            </Col>
                            
                        </Row>
                    }
                </motion.div>
            }
            {(label === "Projektit" && active) &&
                <motion.div
                    variants={variants}
                    animate={inView ? "visible": "hidden"}
                    style={{ backgroundColor:'#ebebeb'}}
                >
                    <Row className="dashboard-survey-row sent-survey-row" onClick={() => handlePermission("limitedProjectManagement")}>
                        <Col xs="8">
                            <p>Rajoitetut Projektit</p>
                        </Col>
                        <Col xs="4">
                            <motion.div
                                animate={(inView && limitedProjects ? 'buttonActive' : 'buttonNotActive')}
                                variants={variants}
                                className="small-white-button"
                                style={{ fontWeight:'bold', textAlign: limitedProjects ? 'left':'right'}}    
                            >
                                
                                {limitedProjects ? 'Aktiivinen' : 'Pois päältä'}
                            </motion.div>
                        </Col>
                    </Row>
                    {limitedProjects && 
                        <Row className="dashboard-survey-row sent-survey-row">
                            <Col xs="8">
                                <div style={{display:'flex', marginLeft:'2em'}}>
                                    <label>Projektien määrä</label> 
                                    <input className="limited-input-bar" name="limited-survey-count" type="text" style={{width: '4em', height:'2em'}} value={projectCount} onChange={(e) => setProjectCount(e.target.value)}></input>
                                </div>
                            </Col>
                            <Col xs="4">
                                <div>
                                    <button className="small-white-button" style={{fontSize: '0.8em', marginTop: '0em'}} onClick={() => handleCountChange({id:"limitedProjectCount", value:projectCount})}>Tallenna</button>
                                </div>
                            </Col>
                            
                        </Row>
                    }
                </motion.div>
            }
        </motion.div>
    )
}

export default PermissionItem;