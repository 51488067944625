import React, {useState, useEffect} from 'react';
import {motion} from 'framer-motion';
import {Row, Col, Input} from 'reactstrap';
import Scrollbars from 'react-custom-scrollbars';
import './info.css';
import {LogoHandler} from './LogoHandler';

const transition = { duration: 0.35, ease: [0.43, 0.13, 0.23, 0.96] };

const listVariants = {
    initial: { scale: 0.9, opacity: 0 },
    enter: { scale: 1, opacity: 1, transition },
    exit: {
        scale: 0.5,
        opacity: 0,
        transition: { duration: 0.35, ...transition }
    }
};

export const EnterpriseInfo = ({enterprise, handleSave, uploadLogo}) => {
    const [edit, setEdit] = useState(false);
    const style = { fontWeight: '300', fontSize: '0.9em' }
    return (
        
        <motion.div variants={listVariants} exit="exit">
            <motion.div
            positionTransition
            exit={{opacity: 0, x: -100}}
            className="enterprise-box">
                <Row>
                    <Col>
                        <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                            <p>Yrityksen tiedot</p>

                            <div style={{ marginLeft: 'auto', marginRight: '1em', marginBottom: '0.3rem' }}>
                                <button className="small-white-button" onClick={() => setEdit(!edit)} style={{fontSize: '0.8em'}}> {!edit ? 'Muokkaa' : 'Peruuta'}</button>
                            </div>
                        </div>
                    </Col>
                </Row>
                
                <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '700px' }}
                        className="custom-scrollbars"
                >
                   {!edit ? 
                        (<div className="enterprise-edit">
                            <Row>
                                <Col xs="2">
                                    <span>Yrityksen nimi:</span>
                                </Col>
                                <Col xs="8">
                                    <span style={style}>{enterprise.businessName}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2">
                                    <span>Y-tunnus:</span>
                                </Col>
                                <Col xs="8">
                                    <span style={style}>{enterprise.businessID}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2">
                                    <span>Osoite:</span>
                                </Col>
                                <Col xs="8">
                                    <span style={style}>{enterprise.address}</span>
                                </Col>
                            </Row>
                            <LogoHandler edit={edit} enterprise={enterprise} logo={enterprise.logo} uploadLogo={ uploadLogo }/>
                        </div>
                        )
                        :
                        <EnterpriseEdit target={enterprise} edit={edit} handleSave={handleSave} toggleEdit={setEdit} uploadLogo={ uploadLogo }/>
                   }
                </Scrollbars>
            </motion.div>
        </motion.div>
    )
}

export const EnterpriseEdit = ({target, edit, handleSave, uploadLogo}) => {
    const [businessName, setBusinessName] = useState(target.businessName);
    const [businessId, setBusinessId] = useState(target.businessID)
    const [address, setAddress] = useState(target.address);

    const onSave = () => {
        let params = {
            ...target,
            businessName: businessName,
            businessID: businessId,
            address: address,
            requestBy: 'admin'
        };
        handleSave(params);
    }

    return (
        <div className="enterprise-edit">
            <Row>
                <Col xs="2">
                    <span>Yrityksen nimi:</span>
                </Col>
                <Col xs="8">
                    <Input 
                        className="input-bar" 
                        name="enterprise-name" 
                        type="text"
                        placeholder="Yrityksen nimi" 
                        value={businessName} 
                        style={{ width: "100%" }} 
                        onChange={(e) => setBusinessName(e.target.value)} /> 
                </Col>
            </Row>
            <Row>
                <Col xs="2">
                    <span>Y-tunnus:</span>
                </Col>
                <Col xs="8">
                    <Input 
                        className="input-bar" 
                        type="text"
                        placeholder="Y-tunnus" 
                        value={businessId} 
                        style={{ width: "100%" }} 
                        onChange={(e) => setBusinessId(e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col xs="2">
                    <span>Osoite:</span>
                </Col>
                <Col xs="8">
                    <Input 
                        className="input-bar"
                        type="text"
                        placeholder="Yrityksen osoite" 
                        value={address} 
                        style={{ width: "100%" }} 
                        onChange={(e) => setAddress(e.target.value)} />
                </Col>
            </Row>
            <LogoHandler edit={edit} enterprise={target} logo={target.logo} uploadLogo={ uploadLogo }/>
            <Row style={{ backgroundColor: '#fff' }}>
                <Col>
                    <button onClick={onSave} style={{ width: '100%' }} className="small-white-button">Tallenna</button>
                </Col>
            </Row>
        </div>
    )
}
