import React, {useState} from 'react';
import {Form, FormGroup, Input, Button} from 'reactstrap';

export const LoginForm = ({onSubmit}) => {
    const [email, setEmail] = useState("");

    return (
        <Form className="white_input" 
            onSubmit={e => {
                e.preventDefault();
                const {email, password} = e.target.elements
                onSubmit({
                    email: email.value,
                    password: password.value
                })
            }}>
            <FormGroup>
                <label htmlFor="email">Sähköposti</label>
                <Input onChange={(e) => setEmail(e.target.value)} className="form-control form-control-lg" id="email" name="email" value={email} placeholder="" type="text"></Input>
            </FormGroup>
            <FormGroup>
                <label htmlFor="password">Salasana</label>
                <Input className="form-control form-control-lg" id="password" placeholder="" type="password" name="password"></Input>
            </FormGroup>
            <FormGroup>
                <Button type="submit" className="btn btn-lg login-button">Kirjaudu</Button>
            </FormGroup>
        </Form>
    )
}