import React, { useState, useEffect, useRef } from 'react';
import {motion} from 'framer-motion';
import PermissionList from './PermissionList';
import {Row, Col, Container} from 'reactstrap';
import {Link} from 'react-router-dom';
import { EnterpriseInfo } from './info/EnterpriseInfo';
import Users from './users/Users';
import {NotificationManager} from 'react-notifications';
import { executeRequest } from '../../../modules/dataActions/dataFetcher';

import {useDispatch} from 'react-redux';

const transition = { duration: 0.35, ease: [0.43, 0.13, 0.23, 0.96] };

const listVariants = {
    initial: { scale: 0.9, opacity: 0 },
    enter: { scale: 1, opacity: 1, transition },
    exit: {
        scale: 0.5,
        opacity: 0,
        transition: { duration: 0.35, ...transition }
    }
};

const Dashboard = ({match}) => {
    const [enterprise, setEnterprise] = useState({});
    const isMounted = useRef(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchEnterprise =  async params => {   
            const response = await executeRequest('get/enterpriseInfo', params, dispatch);
            if(!!response && isMounted.current) {
                setEnterprise(response);
            } else {
                NotificationManager.error("Tietojen haussa tapahtui virhe", "", 3000);
            }
        }
    
        let payload = {
            enterpriseId: match.params.enterprise
        }

        fetchEnterprise(payload);

        return () => {
            isMounted.current = false;
        }
    }, [dispatch, match])

    
    const saveUser = async (params) => {
        const response = await executeRequest('modify/admin/users', params, dispatch);

        if(!!response) {
            if(response.ERROR !== undefined) {
                NotificationManager.error("Sähköposti on jo käytössä", "", 2000);
                return "error";
            } else {
                if(response.STATUS === "EXISTING_USER") {
                    NotificationManager.success('Käyttäjän tiedot päivitetty', '', 2000);
                    const users = handleUsers(response.user, enterprise.users);

                    if(users) {
                        let newEnterpriseInfo = {
                            ...enterprise,
                            users: users
                        }

                        setEnterprise(newEnterpriseInfo);
                        return "done";
                    }
                } else if(response.STATUS === "ADD_NEW_USER") {
                    NotificationManager.success('Käyttäjä lisätty', '', 2000);

                    setEnterprise({
                        ...enterprise,
                        users: [response.user, ...enterprise.users]
                    })
                    
                    return "done";
                }
            }
        } else {
            NotificationManager.error("Tapahtui virhe", "", 3000);
            return "error";
        }
    }

    const removeUser = async userId => {
        const response = await executeRequest('delete/admin/users', {userId: userId, enterpriseId: enterprise.enterpriseId});

        if(!!response) {
            if(response.status && response.status === "deleted") {
                let users = [
                    ...enterprise.users.filter(user => user.id !== response.id)
                ];
                

                NotificationManager.success("Käyttäjä poistettu onnistuneesti", "", 3000);
                setEnterprise({
                    ...enterprise,
                    users: users
                });
            }
        }
    }

    const handleUsers = (editedUser, users) => {
        let userStack = users.map(client => {
            if(client.id === editedUser.id) {
                return editedUser;
            } else {
                return client;
            }
        })
    
        return userStack;
    }

    const handleEnterpriseSave = async (params) => {
        const response = await executeRequest('edit/enterprise', params, dispatch);
    
        if(!!response) {
            NotificationManager.success('Yrityksen tiedot päivitetty', '', 2000);
            setEnterprise(response);
        } else {
            NotificationManager.error("Yrityksen tietojen päivitys epäonnistui", "", 2000);
        }
    }

    const changePermission = async params => {
        const response = await executeRequest('modify/permissions', params, dispatch)
        
        if(!!response) {
            NotificationManager.success("Tila vaihdettu", "", 2000);
            setEnterprise(response)
        } else {
            NotificationManager.error("Tilan vaihdon tallennus epäonnistui", "", 3000);
        }
    }

    const changeTrialActive = async params => {
        const response = await executeRequest('modify/permissions', params, dispatch)
        
        if(!!response) {
            NotificationManager.success("Tila vaihdettu", "", 2000);
            setEnterprise(response)
        } else {
            NotificationManager.error("Tilan vaihdon tallennus epäonnistui", "", 3000);
        }
    }

    const uploadLogo = async (data) => {
        const response = await executeRequest('send/uploadLogo', data, dispatch, false);

        if(!!response) {
            if (response.success) {
                NotificationManager.success("Logo lisätty onnistuneesti", "", 2500);
                setEnterprise({...enterprise, logo: response.logo});
            } else {
                switch (response.error) {
                    case "IMG_SIZE":
                        NotificationManager.error('Tiedoston koko täytyy olla vähemmän kuin 14MB', 'Logon tallennus epäonnistui', 2000);
                        return;
                    case "NOT_IMG":
                        NotificationManager.error('Tiedoston täytyy olla kuvatiedosto', 'Logon tallennus epäonnistui', 2000);
                        return;
                    default:
                        NotificationManager.error('Jokin meni pieleen :(', 'Logon tallennus epäonnistui', 2000);
                        return;
                }
            }
        } else {
            NotificationManager.error("Logon tallennus epäonnistui", "", 3000);
        }
    }

        return (
            <motion.div variants={listVariants} exit="exit">
                <Container fluid className="dashboard-main">
                <Row>
                    <Col xl="12" className="pageTitle">
                        <div className="dashboard-medium-box">
                            <Row>
                                <Col xs="4">
                                    <h2 style={{paddingLeft: '0.5em'}}>{enterprise.businessName}</h2>
                                    {enterprise.businessID && <p style={{paddingLeft: '1em'}}>{enterprise.businessID}</p>}
                                </Col>
                                <Col>
                                    <Row style={{borderBottom:'1px solid #c8c8c8'}} className="enterprise-data">
                                        <Col xs="7"><p style={{ fontWeight:'bold' }}>{"Lomakkeet: "}</p></Col> 
                                        <Col><p>{enterprise.plans}</p></Col>
                                    </Row>
                                    <Row style={{borderBottom:'1px solid #c8c8c8'}} className="enterprise-data">
                                        <Col xs="7"><p style={{ fontWeight:'bold' }}>{"Lomakepohjat: "}</p></Col>
                                        <Col><p>{enterprise.planTemplates}</p></Col>
                                    </Row>
                                    <Row style={{borderBottom:'1px solid #c8c8c8'}} className="enterprise-data">
                                        <Col xs="7"><p style={{ fontWeight:'bold' }}>{"Automaatiopohjat: "}</p></Col>
                                        <Col><p>{enterprise.automations}</p></Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row style={{borderBottom:'1px solid #c8c8c8'}} className="enterprise-data">
                                        <Col xs="7"><p style={{ fontWeight:'bold' }}>{"Aktivoidut kartoitukset: "}</p></Col> 
                                        <Col><p>{enterprise.sentSurveys}</p></Col>
                                    </Row>
                                    <Row style={{borderBottom:'1px solid #c8c8c8'}} className="enterprise-data">
                                        <Col xs="7"><p style={{ fontWeight:'bold' }}>{"Kartoituspohjat: "}</p></Col> 
                                        <Col><p>{enterprise.surveys}</p></Col>
                                    </Row>
                                </Col>
                                {enterprise.fileStatistics &&
                                    <Col xs="">
                                        <Row style={{borderBottom:'1px solid #c8c8c8'}} className="enterprise-data">
                                            <Col xs="7"><p style={{ fontWeight:'bold' }}>{"Liitteet: "}</p></Col> 
                                            <Col><p>{enterprise.fileStatistics.totalAmount}</p></Col>
                                        </Row>
                                        <Row style={{borderBottom:'1px solid #c8c8c8'}} className="enterprise-data">
                                            <Col xs="7"><p style={{ fontWeight:'bold' }}>{"Liitteiden koko: "}</p></Col> 
                                            <Col>
                                                <p>{parseFloat(enterprise.fileStatistics.totalSize) >= 0.5 ? (parseFloat(enterprise.fileStatistics.totalSize).toFixed(2) > 1000 ? (parseFloat(enterprise.fileStatistics.totalSize/1024).toFixed(2) + "GB") : parseFloat(enterprise.fileStatistics.totalSize).toFixed(2) + "MB") : (parseFloat(enterprise.fileStatistics.totalSize*1024).toFixed(2) + "KB")}</p>
                                            </Col>
                                        </Row>
                                        
                                    </Col>
                                }
                                <Col xs="1"></Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                    <motion.div
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    >
                    <Row>
                        <Col xl="12">
                            <Link to="/dashboard">
                                <motion.div
                                style={{display: 'flex', paddingTop: 15}}
                                initial="initial"
                                animate="enter"
                                exit="exit"
                                variants={{ exit: { transition: { staggerChildren: 0.1 } } }}>
                                    <i className="fas fa-arrow-left" style={{fontSize: '1.5em', paddingRight: '0.8em'}}></i><span>Takaisin</span>
                                </motion.div>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                           <PermissionList modulesInUse={ enterprise.modulesInUse || {} } enterpriseId={ enterprise.enterpriseId } changePermission={changePermission} changeTrialActive={changeTrialActive} trial={enterprise.trial || false} trialEnd={enterprise.trialEnd || false}/> 
                        </Col>
                        {/* <Col xl="6">
                            <EnterpriseInfo enterprise={ enterprise } handleSave={ handleEnterpriseSave } uploadLogo={ uploadLogo }/>
                        </Col> */}
                        <Col xs="6">
                            <Users userList={enterprise.users} enterpriseId={ enterprise.enterpriseId } onSave={saveUser} onRemove={removeUser}/>
                        </Col>
                      
                    </Row>
                    </motion.div>
                </Container>
            </motion.div>
        )
}

export default Dashboard;
