import * as ActionTypes from './ActionTypes';
import {confs} from '../../config';
import {authFailed} from '../Login/ActionCreator';
import {NotificationManager} from 'react-notifications'
import {executeRequest} from '../../dataActions/dataFetcher';

const setEnterprises = data => {
    return {
        type: ActionTypes.SET_ENTERPRISES,
        payload: data
    }
}

const setFetchStatus = status => {
    return {
        type: ActionTypes.SET_FETCH_STATUS,
        payload: status
    }
};

export const setEnterprise = enterprise => {
    return {
        type: ActionTypes.SET_ENTERPRISE,
        payload: enterprise
    }
}

export const handleFilter = filter => {
    return {
        type: ActionTypes.SET_FILTER,
        payload: filter
    }
}

export const changePermission = params => async dispatch => {

    dispatch(setFetchStatus(true));
    const response = await executeRequest('modify/permissions', params, dispatch)
    
    if(!!response) {
        NotificationManager.success("Tila vaihdettu", "", 2000);
        dispatch(setEnterprise(response))
    } else {
        NotificationManager.error("Tilan vaihdon tallennus epäonnistui", "", 3000);
    }
    dispatch(setFetchStatus(false));
}

export const fetchEnterprises = params => async dispatch => {    
    const response = await executeRequest('get/enterpriselist', params, dispatch);
    console.log(!response, !!response, response)
    if(!!response) {
        dispatch(setEnterprises(response));
    } else {
        NotificationManager.error("Tietojen haussa tapahtui virhe", "", 3000);
    }

    dispatch(setFetchStatus(false));
    
}

export const saveLogo = params => {
    return {
        type: ActionTypes.SAVE_LOGO,
        payload: params
    }
}

export const uploadLogo = (data) => async dispatch => {
    
    const response = await executeRequest('send/uploadLogo', data, dispatch, false);

    if(!!response) {
        if (response.success) {
            NotificationManager.success("Logo lisätty onnistuneesti", "", 2500);
            dispatch(saveLogo(response.logo))
        } else {
            switch (response.error) {
                case "IMG_SIZE":
                    NotificationManager.error('Tiedoston koko täytyy olla vähemmän kuin 14MB', 'Logon tallennus epäonnistui', 2000);
                    return;
                case "NOT_IMG":
                    NotificationManager.error('Tiedoston täytyy olla kuvatiedosto', 'Logon tallennus epäonnistui', 2000);
                    return;
                default:
                    NotificationManager.error('Jokin meni pieleen :(', 'Logon tallennus epäonnistui', 2000);
                    return;
            }
        }
    } else {
        NotificationManager.error("Logon tallennus epäonnistui", "", 3000);
    }
    
}

export const editEnterprise = params => async dispatch => {
    const response = await executeRequest('edit/enterprise', params, dispatch);
    
    if(!!response) {
        NotificationManager.success('Yrityksen tiedot päivitetty', '', 2000);
        dispatch(setEnterprise(response));
    } else {
        NotificationManager.error("Yrityksen tietojen päivitys epäonnistui", "", 2000);
    }
}

const setUserData = user => {
    return {
        type: ActionTypes.SET_USER_DATA,
        payload: user
    }
}

const addNewUser = newUser => {
    return {
        type: ActionTypes.ADD_NEW_USER,
        payload: newUser
    }
}

export const saveUserData = params => async dispatch => {
    const response = await executeRequest('modify/admin/users', params, dispatch);

    if(!!response) {
        if(response.ERROR !== undefined) {
            NotificationManager.error("Sähköposti on jo käytössä", "", 2000);
        } else {
            if(response.STATUS === "EXISTING_USER") {
                NotificationManager.success('Käyttäjän tiedot päivitetty', '', 2000);
                dispatch(setUserData(response));
            } else if(response.STATUS === "ADD_NEW_USER") {
                NotificationManager.success('Käyttäjä lisätty', '', 2000);
                dispatch(addNewUser(response));
            }
        }
    } else {
        NotificationManager.error("Tapahtui virhe", "", 3000);
    }
}

const saveNewEnterprise = params => {
    console.log("ADD", params);
    return {
        type: ActionTypes.ADD_NEW_ENTERPRISE,
        payload: params
    }
}

export const addNewEnterprise = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'add/enterprise', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed())
                } else {
                    if(responseJson.status === undefined) {
                        NotificationManager.success('Uusi Enterprise lisätty', '', 2000);
                        dispatch(saveNewEnterprise(responseJson));
                    } else {
                        NotificationManager.error("Y-tunnuksella on jo lisätty yritys", "", 3000);
                    }
                }
            })
    }
}

export const getEnterpriseInfo = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    
    return dispatch => {
        dispatch(setFetchStatus(true));
        fetch(confs.url + 'get/enterpriseInfo', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed())
                } else {
                    dispatch(setEnterprise(responseJson));
                }
                dispatch(setFetchStatus(false));
            })
            .catch(e => {
                NotificationManager.error("Valitettavasti yrityksen tietojen haku epäonnistui", "Virhe", 2000);
                dispatch(setFetchStatus(false));
            })
    }
}