import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { store_Login } from '../../modules/actions/Login/ActionCreator'
import { executeRequest } from '../../modules/dataActions/dataFetcher'
export const StagingArea = ({ history }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const checkPermission = async (params) => {
            const response = await executeRequest('login/authCheck', params, dispatch)
            if(response.Status === 202) {
                dispatch(store_Login({isAuthenticated: true}));
                history.push('/dashboard')
            } else {
                history.push('/login');
            }
        }

        checkPermission();
    }, [dispatch, history])

    return <div></div>
};