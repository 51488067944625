import React from 'react'
import {
  Navbar
} from 'reactstrap';

import './navbar.css';


class NavigationBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.toggleNavExpand = this.toggleNavExpand.bind(this);
  }

  toggleNavExpand() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  }

  render() {
    return (
      <span>
        <Navbar expand="md" light>
            <div className="navbar-brand" style={{width: 90}}>
                <div className="qibbie-logo"></div>
            </div>
            <div><h3 style={{paddingLeft: '0.5em'}}>Enterprise Admin Portal</h3></div>
        </Navbar>
      </span>
    )
  }
}

export default NavigationBar;