import React from 'react';
import {connect} from 'react-redux';
import {withRouter, Route, Switch, Redirect} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
import {StagingArea} from '../auth/StagingArea';
import EnterprisesContainer from '../enterprise-components/EnterprisesContainer';
import Dashboard from '../enterprise-components/enterprise-edit/Dashboard';
import Login from '../auth/Login';
import NavigationBar from './NavigationBar';
import CodeLogin from '../auth/LoginCode';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
    <Route 
        {...rest} 
        render={props => (
            isAuthenticated === true ? (
                <Component {...props}/>
                ) : (
                  <Redirect 
                    to="/login"
                  />
                )
            )} 
    />
)

class Navigator extends React.Component {
    render() {
        return (
            <>
                <header>
                <NavigationBar />
                </header>
                <main role="main">
                    <AnimatePresence exitBeforeEnter initial={false}>
                        <Switch location={this.props.history.location}>
                            <Route exact path="/login" component={Login} />
                            <PrivateRoute path="/login/code" exact component={CodeLogin} isAuthenticated={this.props.loginSuccess} />
                            <PrivateRoute path="/dashboard" exact component={EnterprisesContainer} isAuthenticated={this.props.isAuthenticated} />
                            <PrivateRoute path="/dashboard/edit/:enterprise" exact component={Dashboard} isAuthenticated={this.props.isAuthenticated} />
                            <Route path="*" component={StagingArea}/>
                        </Switch>
                    </AnimatePresence>
                </main>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        loginSuccess: state.authentication.loginSuccess
    }
}

export default withRouter(connect(mapStateToProps, null)(Navigator));