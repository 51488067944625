import React, {useState, useEffect} from 'react';
import {Row, Col, Input} from 'reactstrap';
import {NotificationManager} from 'react-notifications';
import moment from 'moment';

export const User = ({oddEvenClass, userInfo, onSave, onRemove, newUser = false, enterpriseId}) => {
    const [user, setUser] = useState(userInfo);
    const [editUser, setEditUser] = useState(false);
    
    useEffect(() => {
        setUser(userInfo);
    }, [userInfo])
    
    return (
        (!editUser && !newUser) ? (
        <Row key={userInfo.id} className={oddEvenClass + ' enterprise-list '}>
            <Col xs="12">
                <Row>
                    <Col xs="6">
                        {user.email}
                    </Col>
                    <Col xs="4">
                        {user.lastLogin&& <span>{moment.utc(user.lastLogin, 'MMM D, YYYY h:m:s A', 'en' ).local().format( 'DD.MM.YYYY HH:mm' )}</span>}
                       
                    </Col>
                    <Col xs="2" style={{ textAlign: 'right' }}>
                        <div style={{ marginLeft: 'auto'}}>
                            <button className="small-white-button lighter-text wide-button" onClick={() => setEditUser(true)}>Muokkaa</button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <p className="lighter-text">{user.phone}</p>
                    </Col>
                    <Col>
                        {user.adminUser && <span style={{ color: 'red' }}>admin</span>}
                    </Col>
                </Row>
            </Col>
        </Row>
        ) : (
            <EditUser oddEvenClass={oddEvenClass} userInfo={user} handleSave={onSave} removeUser={onRemove} setEditUser={setEditUser} enterpriseId={enterpriseId} newUser={newUser} />
        )
    )
}


export const EditUser = ({oddEvenClass, userInfo, removeUser, handleSave, setEditUser, newUser, enterpriseId}) => {
    const [user, setUser] = useState(userInfo);
    const handleField = (field, value) => {
        let object = {
            ...user,
            [field]: value
        };

        setUser(object)
    }

    const handleUserSave = async ( sendNewCode = false ) => {

        const emailCheck = /[^@]+@[^.]+\..+/;
        let editeduser = {
            ...user,
            sendNewLoginToken: sendNewCode,
            requestBy: "admin",
            enterpriseId: enterpriseId
        };
        
        if (emailCheck.test(editeduser.email)) {
            const response = await handleSave(editeduser);
            if(response === 'done') {
                setEditUser(false);
            }
        } else {
            NotificationManager.error('Tarkista sähköpostiosoite!', 'Virhe', 2000);
        }
    }

    return (
        <Row key={userInfo.id} className={oddEvenClass + ' enterprise-list '}>
            <Col xs="12">
                <Row>
                    <Col xs="8">
                        <div style={{ lineHeight: '0.2em' }}>
                            <p style={{ paddingBottom: '1.3em', paddingTop: '0.6em' }}>{newUser ? 'Luo uusi käyttäjä' : 'Muokkaa käyttäjää'}</p>
                            <label className="lighter-text" >Sähköposti</label>
                            <Input className="input-bar" type="text" name="email" placeholder="Sähköposti" value={user.email} style={{ width: "100%" }} onChange={(e) => handleField("email", e.target.value.trim())}></Input>
                        </div>
                    </Col>
                    <Col xs="4" style={{ textAlign: 'right' }}>
                        {!newUser &&
                        <>
                            <div style={{ marginLeft: 'auto'}}>
                                <button className="small-white-button lighter-text wide-button" onClick={() => setEditUser(false)}>Peruuta</button>
                            </div>
                            <div style={{ marginLeft: 'auto', paddingTop: '1em'}}>
                                <button className="small-white-button lighter-text wide-button delete" onClick={() => removeUser(user.id)}>Poista</button>
                            </div>
                        </>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="8">
                        <div style={{ lineHeight: '0.2em', marginTop: '1em' }}>
                            <label className="lighter-text" >Puhelinnumero</label>
                            <Input className="input-bar" name="phone" type="text" value={user.phone} placeholder="Puhelinnumero" style={{ width: "100%" }} onChange={(e) => handleField("phone", e.target.value)}></Input>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" style={{ marginTop: '1em', marginBottom: '0.2em' }}>
                        <button className="small-white-button lighter-text wide-button" onClick={() => handleUserSave(false)} >Tallenna</button>
                        {!newUser && <button className="small-white-button lighter-text wide-button" onClick={() => handleUserSave(true)} style={{ marginLeft: '0.5rem' }} >Tallenna ja lähetä kirjautumiskoodi</button> }
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}