import React from 'react';
import {motion} from 'framer-motion';
import {Container, Row} from 'reactstrap';
import {LoginForm} from './LoginForm';
import './login.css';
import {useDispatch} from 'react-redux';
import * as ActionCreator from '../../modules/actions/Login/ActionCreator';
import {executeRequest} from '../../modules/dataActions/dataFetcher';
import {NotificationManager} from 'react-notifications';
const transition = { duration: 0.55, ease: [0.43, 0.13, 0.23, 0.96] };

const listVariants = {
    initial: { scale: 0.9, opacity: 0 },
    enter: { scale: 1, opacity: 1, transition },
    exit: {
        scale: 0.5,
        opacity: 0,
        transition: { duration: 0.55, ...transition }
    }
};

const Login = ({history}) => {
    const dispatch = useDispatch();

    const onSubmit = async (params) => {
        const response = await executeRequest('login/fetchPermission', params, dispatch)
    
        if(!!response) {
            let data = {
                ...response,
                loggedInEmail: params.email
            };
            dispatch(ActionCreator.store_Login(data));
            history.push('/login/code');
            return;
        }
    
        NotificationManager.error("Käyttäjä / salasana ei täsmää", "", 3000);
    }
        
    return (
        <motion.div variants={listVariants} exit="exit" className="h-100" key="logInwindow">
            <Container className="h-100">
                    <motion.div
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
                        className="h-100"
                    >
                        <Row className="justify-content-center align-items-center h-100">
                            <div className="col-md-8 sm-12 greybox loginbox">
                                <div>
                                    <LoginForm onSubmit={onSubmit} />
                                </div>
                            </div>
                        </Row>
                    </motion.div>
            </Container>
        </motion.div>
    )
}

export default Login;
