import React, {useState, useEffect, useRef} from 'react';
import {motion} from 'framer-motion';
import EnterpiseListItem from './EnterpriseListItem';
import {Scrollbars} from 'react-custom-scrollbars';
import NewEnterprise from './enterprise-edit/enterprise-add/NewEnterprise';
import {Row, Col} from 'reactstrap';

export const EnterpriseList = ({enterprises, onSave}) => {
    const [filter, setFilter] = useState("");
    const [edit, setEdit] = useState(false);
    const itemRef = useRef();

    useEffect(() => {
        if(edit)
            itemRef.current.scrollToTop();
    }, [edit]);

    const handleEnterpriseAdd = async params => {
        const response = await onSave(params);

        if(response) {
            setEdit(false);
        }
    }
    return (
        <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
        >
            <Row>
                <Col xl="12">
                    <div className="dashboard-medium-box">
                        <div className="box-header bigger-text" style={{ display: 'flex' }}>
                            <p>Enterprise listaus</p>
                            <div style={{ marginLeft: '0.5em' }}>
                                <form id="search-bar">
                                    <input type="search" className="search-bar-input" placeholder="Etsi kartoitusta" value={ filter } onChange={( event ) => setFilter(event.target.value) } />
                                </form>
                            </div>
                            <div style={{ marginLeft: 'auto', marginRight: '1em' }}>
                                <button className="small-white-button lighter-text wide-button" onClick={() => setEdit(!edit)}>{edit ? "Peruuta" : "Lisää uusi"}</button>
                            </div>
                        </div>
                        <Scrollbars
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                            style={{ width: "100%", height: '600px' }}
                            ref={itemRef}
                            className="custom-scrollbars"
                        >
                        {edit && 
                            <NewEnterprise save={handleEnterpriseAdd}/>
                        }
                        {enterprises.filter(enterprise => enterprise.businessName.toLowerCase().includes(filter)).map((enterprise, index) => {
                            return(
                                <EnterpiseListItem item={enterprise} key={index} />
                            )
                        })}
                        </Scrollbars>
                    </div>
                </Col>
            </Row>
        </motion.div>
    )
}