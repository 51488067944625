import React, {useState} from 'react';
import {motion} from 'framer-motion';
import {Row, Col, Input} from 'reactstrap';

const transition = { duration: 0.35, ease: [0.43, 0.13, 0.23, 0.96] };

const listVariants = {
    initial: { scale: 0.9, opacity: 0 },
    enter: { scale: 1, opacity: 1, transition },
    exit: {
        scale: 0.5,
        opacity: 0,
        transition: { duration: 0.35, ...transition }
    }
};

const NewEnterprise = ({save}) => {
    const [businessName, setName] = useState("");
    const [businessID, setBusinessId] = useState("");
    const [address, setAddress] = useState("");

    return (
        <motion.div variants={listVariants} exit="exit">
            <motion.div
            positionTransition
            exit={{opacity: 0, x: -100}}
            className="enterprise-box">
                <Row>
                    <Col>
                        <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                            <p>Uusi Enterprise</p>
                        </div>
                    </Col>
                </Row>
                <div className="enterprise-edit">
                    <Row>
                        <Col xs="2">
                            <span>Yrityksen nimi:</span>
                        </Col>
                        <Col xs="8">
                            <Input className="input-bar" placeholder="Yrityksen nimi" name="enterprise-name" id="enterpriseName" type="text" value={businessName} style={{ width: "100%" }} onChange={(e) => setName(e.target.value)}></Input>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="2">
                            <span>Y-tunnus:</span>
                        </Col>
                        <Col xs="8">
                            <Input className="input-bar" placeholder="Y-tunnus" type="text" id="businessId" value={businessID} style={{ width: "100%" }} onChange={(e) => setBusinessId(e.target.value)}></Input>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="2">
                            <span>Osoite:</span>
                        </Col>
                        <Col xs="8">
                            <Input className="input-bar" placeholder="Osoite" id="address" type="text" value={address} style={{ width: "100%" }} onChange={(e) => setAddress(e.target.value)}></Input> 
                        </Col>
                    </Row>
                    <Row style={{ backgroundColor: '#fff' }}>
                        <Col>
                            <button style={{ width: '100%' }} className="small-white-button" onClick={() => save({businessName: businessName, businessID: businessID, address: address})}>Tallenna</button>
                        </Col>
                    </Row>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default NewEnterprise;