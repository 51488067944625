import * as ActionTypes from '../modules/actions/userActions/actionTypes';

const initialState = {
    loginSuccess: false,
    codeSuccess: false,
    registered: [],
    loginEmail: '',
    redirectUrl: '',
    codeLoginInProgress: false,
    userId: ""
}

export default function(state = initialState, action) {
  switch(action.type) {
    case ActionTypes.LOGIN:
      return {
        ...state,
        ...action.payload
      }
    case ActionTypes.CODE_LOGIN:
      return {
        ...state,
        ...action.payload
      }
    case ActionTypes.CODE_LOGIN_PROGRESS:
      return {
        ...state,
        ...action.payload
      }
    case ActionTypes.FIRST_LOGIN_ISVALID:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state;
  }
}

