import React from 'react';
import './App.css';
import Navigator from './Components/navigation/Navigator';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import 'react-dates/initialize'

function App() {
  return (
      <span>
            <Navigator />
            <NotificationContainer />
      </span>
  );
}

export default App;
