import * as ActionTypes from '../modules/actions/Login/ActionTypes';

const initialState = {
    isAuthenticated: false,
    authenticationFailed: false,
    loginSuccess: false,
    codeLogin: false,
    loggedInEmail: "",
    agreement: false,
    userinfo: {
        company: ""
    },
    downloadNew: false,
    userType: "admin"
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.CHECK_LOGIN: 
            return {
                ...state,
                ...action.payload
            };
        case ActionTypes.CODE_LOGIN:
            return {
                ...state,
                ...action.payload
            }
        case ActionTypes.AUTH_FAILED:
            return {
                ...state,
                isAuthenticated: false,
                authenticationFailed: true,
                loginSuccess: false
            }
        default: {
            return state;
        }
        
    }
}


export default reducer;