import React, { useEffect, useState, useRef } from 'react';
import {Row, Col, Container} from 'reactstrap';
import {motion} from 'framer-motion'
import {executeRequest} from '../../modules/dataActions/dataFetcher';
import {NotificationManager} from 'react-notifications';
import {useDispatch} from 'react-redux';

import {EnterpriseList} from './EnterpriseList';

const transition = { duration: 0.35, ease: [0.43, 0.13, 0.23, 0.96] };

const listVariants = {
    initial: { scale: 0.9, opacity: 0 },
    enter: { scale: 1, opacity: 1, transition },
    exit: {
        scale: 0.5,
        opacity: 0,
        transition: { duration: 0.35, ...transition }
    }
};

const EnterprisesContainer = () => {
    const dispatch = useDispatch();

    const isMounted = useRef(true);
    const [enterprises, setEnterprises] = useState([]);
    

    useEffect(() => {
        
        const fetchList = async (params) => {
            const response = await executeRequest('get/enterpriselist', params, dispatch);
            if(!!response) {
                if(isMounted.current)
                    setEnterprises(response);
            } else {
                NotificationManager.error("Tietojen haussa tapahtui virhe", "", 3000);
            }
        }

        fetchList();

        return () => {
            isMounted.current = false;
        }
    }, [dispatch])
    

    const saveNewEnterprise = async params => {
        const response = await executeRequest('add/enterprise', params, dispatch);

        if(!!response) {
            if(isMounted.current) {
                if(response.status === undefined) {
                    setEnterprises([response, ...enterprises]);
                    NotificationManager.success('Uusi Enterprise lisätty', '', 2000);
                    return true;
                } else {
                    NotificationManager.error("Y-tunnuksella on jo lisätty yritys", "", 3000);
                    return false;
                }
            }
        } else {
            NotificationManager.error("Uuden enterprisen lisäyksessä tapahtui virhe", "", 3000);
            return false;
        }
    }
    
    return (
        <motion.div variants={listVariants} exit="exit">
            <Container fluid className="dashboard-main">
            <Row>
                <Col xl="12" className="pageTitle">
                <div className="dashboard-medium-box">
                    <h2 style={{paddingLeft: '0.5em'}}>Yrityslistaus</h2>
                </div>
                </Col>
            </Row>
            <EnterpriseList enterprises={enterprises} onSave={saveNewEnterprise} />            
            </Container>
        </motion.div>
    )
}

export default EnterprisesContainer;