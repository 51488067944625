import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import BoxSearchBar from '../../../../modules/BoxSearchBar/BoxSearchBar';
import {User} from './User';
const Users = ( { userList, onSave, enterpriseId, onRemove } ) => {
    const [filter, setFilter] = useState('');
    const [newUser, setNewUser] = useState(false);
    const itemRef = useRef();

    useEffect(() => {
        if(newUser)
            itemRef.current.scrollToTop();
    }, [newUser]);

    useEffect(() => {
         setNewUser(false);
    }, [userList])

    let i = 0;
        return (
            <div>
                <Col xl={{ size: 12 }} className="enterprise-box">
                    <Row>
                        <Col>
                            <div className="box-header bigger-text" style={{ display: 'flex' }}>
                                <p>Käyttäjät</p>
                                <BoxSearchBar placeholder='Etsi käyttäjää' value={ filter } onChange={ (val) => setFilter(val)  } />
                                <div style={{ marginLeft: 'auto', marginRight: '1em', marginTop:'-0.25em' }}>
                                    <button className="small-white-button lighter-text wide-button" onClick={() => setNewUser(!newUser)}>{newUser ? "Peruuta" : "Lisää uusi"}</button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <Scrollbars
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                style={{ width: "100%", height: '500px' }}
                                ref={itemRef}
                                className="custom-scrollbars">
                                {newUser && <User oddEvenClass={'odd-row'} userInfo={{email: "", phone: ""}} onSave={onSave} enterpriseId={enterpriseId} newUser />}
                                {userList && userList.map((user, y) => {
                                    if ((user.email.toLowerCase().includes(filter.toLowerCase())) || (user.phone.toLowerCase().includes(filter.toLowerCase()))) {
                                        let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row';
                                        i++;
                                        return <User oddEvenClass={oddEvenClass} userInfo={user} onSave={onSave} onRemove={onRemove} enterpriseId={enterpriseId} key={user.id} />
                                    }
                                    return null
                                })}
                                {i === 0 &&
                                    <Col style={{ marginTop: '1em' }}>
                                        <span>Käyttäjiä ei löytynyt</span>
                                    </Col>
                                }
                            </Scrollbars>
                        </Col>
                    </Row>
                </Col>
            </div>
        )
    }

export default Users;