import React, {useState, useEffect, useRef} from 'react'
import Spinner from '../../../modules/LoadingSpinner';
import './codeInput.css'

/** Only a sketch, waiting for proper specs */

const emptyInputValues = ['', '', '', '', ''];

export const CodeInput = ({onSubmit, codeLoginAttempt}) => {
  const [codeValues, setCodeValues] = useState(emptyInputValues)
  const codeInputs = useRef([]);

  useEffect(() => {
    codeInputs.current[0].focus();
    
  }, [])

  const onCodeInput = (e) => {
    const inputIndex = codeInputs.current.indexOf(e.target)
    if(e.target.value.length === 1) {
      let newCodeInputs = [...codeValues]
      newCodeInputs[inputIndex] = e.target.value

      setCodeValues(newCodeInputs)

      if(codeInputs.current[inputIndex + 1]) {
        codeInputs.current[inputIndex + 1].focus()
      } else {
        if(isCodeReady()) {
          getCodeAndSubmit()
        }
      }
    } 
  }

  const getCodeAndSubmit = () => {
    let code = ''
    codeInputs.current.map(input => {
      if(input) {
        return code += input.value.toUpperCase()
      }
      return null;
    })

    setCodeValues(emptyInputValues);
    codeInputs.current[0].focus();
    handleSubmit(code)
  }

  const handleBackspace = (e) => {
    if(e.key === 'Backspace') {
      const inputIndex = codeInputs.current.indexOf(e.target)

      let newCodeValues = [...codeValues];
      newCodeValues[inputIndex] = '';
      newCodeValues[inputIndex - 1] = '';

      setCodeValues(newCodeValues);

      if(codeInputs.current[inputIndex - 1]) {
        codeInputs.current[inputIndex - 1].focus()
      }
    }
  }

  const handleSubmit = (code) => {
    onSubmit(code)
  }

  const onCodePaste = (e) => {
    const startIndex = codeInputs.current.indexOf(e.target)
    const pastedCode = e.clipboardData.getData('Text').split('')
    let updatedValues = [...codeValues]
    let lastIndex = 0

    pastedCode.map(( char, index ) => {
      if(codeInputs.current[ startIndex + index ]) {
        updatedValues[startIndex + index] = char
        codeInputs.current[ startIndex + index ].value = char
        lastIndex = startIndex + index
      }
      return null;
    })

    setCodeValues(updatedValues);

    setTimeout( () => {
      if( isCodeReady() ) {
        codeInputs.current[ lastIndex ].focus()
        getCodeAndSubmit()
      }
    }, 200)
  }

  const isCodeReady = () => {
    let codeIsReady = true

    codeInputs.current.map(( input ) => {
      if( codeIsReady && input && input.value.trim() === '' ) {
        codeIsReady = false
        input.focus()
      }
      return null;
    })

    return codeIsReady
  }

  const handleFocus = ( e ) => {
    e.target.select()
  }

  return (
    <div className="container-fluid codeinput-container">
      {codeLoginAttempt ? (
        <div className="row">
          <Spinner class='block-loader' />
        </div>
      ) : (
        <div className='row codeinput-row'>
          <form className="form-inline">
            <div className="input-group">
              {codeValues.map((field, index) => 
                <input key={index} value={field} ref={(input) => {codeInputs.current[index] = input}} onKeyDown={(e) => handleBackspace(e)} onChange={(e) => onCodeInput(e)} className='code-cell text-center' type='text' maxLength="1" tabIndex={index+1} onPaste={onCodePaste} onFocus={handleFocus} />
              )}
            </div>
          </form>
        </div>
      )}
      
    </div>
  )
}