import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import dotenv from 'dotenv'

//Redux
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

//Dev tools
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

//Reducers
import enterpriseReducer from './reducers/enterpriseReducer';
import userReducer from './reducers/userReducer';
import Login from './reducers/Login';
//Router
import {Router} from 'react-router-dom';

//History
import history from './Components/navigation/historyCreator';
dotenv.config();
const rootReducer = combineReducers({
    authentication: Login,
    enterprise: enterpriseReducer,
    user: userReducer
});


const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

ReactDOM.render(<Provider store={store}><Router history={history}><App /></Router></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
