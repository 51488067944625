export const SET_ENTERPRISES = "SET_ENTERPRISES";
export const SET_FETCH_STATUS = "SET_FETCH_STATUS";
export const SET_FILTER = "SET_FILTER";
export const SET_ENTERPRISE = "SET_ENTERPRISE";
export const CHANGE_PERMISSION =  "CHANGE_PERMISSION";
export const SAVE_LOGO = "SAVE_LOGO";
export const ADD_NEW_ENTERPRISE = "ADD_NEW_ENTERPRISE";
export const SET_USER_DATA = "SET_USER_DATA";
export const ADD_NEW_USER = "ADD_NEW_USER";

