import React from 'react';
import {Col, Row} from 'reactstrap';
import {motion} from 'framer-motion';
import { useInView } from 'react-intersection-observer'
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setEnterprise} from '../../modules/actions/Enterprise/ActionCreator';
const EnterpriseListItem = ({item, selection}) => {
    const [ref, inView] = useInView({
        threshold: 0,
    })
    const dispatch = useDispatch();

    const variants = {
        visible: i => ({
            opacity: 1,
            x: 0,
            transition: {delay: i * 0.3, ease: 'easeIn', duration: 0.2}
        }),
        hidden: {
            opacity: 0
        }
    }

    return (
        <motion.div
            initial={{opacity: 0, x: 150}}
            animate={"visible"}
            variants={variants}
            exit={{opacity: 0, x: -100}}
            ref={ref}
            style={{}}
            positionTransition
        >
            <Link to={`/dashboard/edit/${item.enterpriseId}`} onClick={() => dispatch(setEnterprise(item))}>
                <Row className="dashboard-survey-row sent-survey-row">
                    <Col xl="12">
                        {item.businessName || "" }
                    </Col>
                </Row>
            </Link> 
        </motion.div>
    )
}

export default EnterpriseListItem;